import { Flex, Box, Link as ChakraLink, Text, Badge } from '@chakra-ui/react';
import Image from 'next/image';
import { useSetAtom } from 'jotai';
import { filterGeoLocationAtom } from '@/atoms/filter';

const MobileMapPointCard = ({ data }) => {
  const {
    id: building_id,
    spaces,
    name,
    min_price,
    max_price,
    tags,
    subscription_solution,
    is_subscription_productify,
  } = data || {};

  const shortBuildingId =
    building_id?.length === 36 ? building_id?.substring(0, 8) : building_id;

  const setFilterGeoLocation = useSetAtom(filterGeoLocationAtom);

  const { url } = spaces?.length > 0 ? spaces.filter(({ url }) => url)[0] : {};

  const handleClickItem = () => {
    setFilterGeoLocation({
      lat: data.lat,
      lng: data.lon,
    });
  };

  if (!building_id) return null;

  return (
    <Box
      pos='fixed'
      lef='10%'
      bottom={10}
      zIndex={1009}
      w='100%'
      h='180px'
      boxShadow='lg'
      alignItems='stretch'
      p={5}>
      <Flex bg='white' borderRadius='lg' h='140px'>
        <Box
          w='140px'
          h='140px'
          pos='relative'
          bg='black'
          borderLeftRadius='lg'
          overflow='hidden'>
          {url && (
            <ChakraLink
              href={`/b/${shortBuildingId}`}
              target='_blank'
              pos='relative'
              display='block'
              w='100%'
              h={140}
              bg='black'
              onClick={handleClickItem}>
              <Image
                src={url}
                fill
                sizes='100% 100%'
                alt={name}
                style={{
                  objectFit: 'cover',
                }}
              />
            </ChakraLink>
          )}
        </Box>
        <Flex flex='1' p={4} direction='column'>
          <a
            href={`/b/${shortBuildingId}`}
            onClick={handleClickItem}
            target='_blank'>
            <Flex alignItems='center'>
              {subscription_solution?.fully_booked ? (
                <Badge colorScheme='red' mr={2}>
                  預約候補
                </Badge>
              ) : null}
              <Text fontWeight={500} fontSize={16}>
                {name}
              </Text>
            </Flex>

            <Text fontSize={14} color='primary.gray' noOfLines={2}>
              {tags.map((tag) => tag.name).join(' ')}
            </Text>

            {!is_subscription_productify && subscription_solution && (
              <Text fontSize={14} color='primary.gray' noOfLines={2}>
                {subscription_solution.description}
              </Text>
            )}

            <Flex align='center' fontSize={16}>
              {data?.min_price && (
                <>
                  <Text>${data.min_price}</Text>
                  <Text color='primary.gray' mx='2'>
                    TWD/月起
                  </Text>
                </>
              )}
            </Flex>
          </a>
        </Flex>
      </Flex>
    </Box>
  );
};

export default MobileMapPointCard;
