import { memo } from 'react';
import { useSetAtom } from 'jotai';
import { selectedBuildingIdAtom } from '@/atoms/buildings';

import MapPointPresentation from './MapPointPresentation';

const MapPointContainer = ({ map, cluster, data, isOpen }) => {
  const setSelectedBuildingId = useSetAtom(selectedBuildingIdAtom);
  const handleClick = (e) => {
    e.stopPropagation();
    setSelectedBuildingId((prevId) => (prevId === data.id ? null : data.id));
  };

  return (
    <MapPointPresentation
      map={map}
      cluster={cluster}
      data={data}
      isOpen={isOpen}
      onClick={handleClick}
    />
  );
};

export default memo(MapPointContainer);
