import { atom } from 'jotai';

export const flashMessageAtom = atom({
  message: '',
  type: '',
  isOpen: false,
});

export const clearFlashMessageAtom = atom(null, (get, set) => {
  set(flashMessageAtom, {
    message: '',
    type: '',
    isOpen: false,
  });
});
