const getEuclideanDistance = (p1, p2) => {
  var dx = p1.lat - p2.lat;
  var dy = p1.lon - p2.lon;
  return Math.sqrt(dx * dx + dy * dy);
};

export const sortMapPointsWithCenter = (center, points = []) => {
  return points.sort((a, b) => {
    return getEuclideanDistance(center, a) - getEuclideanDistance(center, b);
  });
};
