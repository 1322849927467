import { motion } from 'framer-motion';
import { Box, Grid, GridItem, IconButton, Text } from '@chakra-ui/react';
import { ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons';
import { useAtomValue, useAtom } from 'jotai';
import { buildingsAtom, isBuildingListDrawerOpenAtom } from '@/atoms/buildings';
import {
  filterMapBoundsAtom,
  filterQueryInputAtom,
  filterCacheGeoLocationAtom,
} from '@/atoms/filter';
import { sortMapPointsWithCenter } from '@/utils/index';

import BuildingItem from './BuildingItem';

const BuildingList = () => {
  const [isOpen, setIsOpen] = useAtom(isBuildingListDrawerOpenAtom);
  const onToggle = () => setIsOpen((isOpen) => !isOpen);

  const buildings = useAtomValue(buildingsAtom);
  const filterMapBounds = useAtomValue(filterMapBoundsAtom);
  const center = useAtomValue(filterCacheGeoLocationAtom);
  const queryInput = useAtomValue(filterQueryInputAtom);

  const isUseBounds =
    filterMapBounds?.ne.lat !== filterMapBounds?.sw.lat &&
    filterMapBounds?.ne.lng !== filterMapBounds?.sw.lng;
  let filteredBuildings = isUseBounds
    ? buildings?.filter((building) => {
        const { lat, lon } = building;
        const { ne, sw } = filterMapBounds;
        return lat >= sw.lat && lat <= ne.lat && lon >= sw.lng && lon <= ne.lng;
      })
    : buildings;
  filteredBuildings = sortMapPointsWithCenter(
    {
      lat: center?.lat,
      lon: center?.lng,
    },
    filteredBuildings,
  );

  // 將搜尋結果放在最前面
  if (queryInput) {
    const index = filteredBuildings.findIndex(
      (building) => building.name === queryInput,
    );
    if (index !== -1) {
      const [building] = filteredBuildings.splice(index, 1);
      filteredBuildings = [building, ...filteredBuildings];
    }
  }

  return (
    <>
      <motion.div
        initial={{
          x: -552,
        }}
        variants={{
          open: { x: 0 },
          closed: { x: -552 },
        }}
        animate={isOpen ? 'open' : 'closed'}
        transition={{ type: 'ease' }}
        style={{
          width: 552,
          height: 'calc(100vh - 60px)',
          backgroundColor: 'pink',
          position: 'absolute',
          zIndex: 10,
          boxShadow: '0px 1px 4px rgba(0, 0, 0, 0.25)',
        }}>
        <Box
          w='100%'
          h='100%'
          bg='white'
          overflowX='hidden'
          overflowY='auto'
          p={6}>
          <Text as='h1' fontSize='md' fontWeight='bold' mb={6}>
            {filteredBuildings?.length !== 0
              ? `${filteredBuildings?.length} 個停車場`
              : '你選擇的範圍內目前沒有停車場哦！'}
          </Text>

          <Grid templateColumns='repeat(2, minmax(240px, 1fr))' gap={6}>
            {filteredBuildings?.map((building) => (
              <GridItem
                overflow='hidden'
                key={`building-list-item-${building.id}`}>
                <BuildingItem data={building} />
              </GridItem>
            ))}
          </Grid>
        </Box>
      </motion.div>
      <motion.div
        initial={{
          x: 16,
          y: 16,
        }}
        variants={{
          open: { x: 552 + 16 },
          closed: { x: 16 },
        }}
        animate={isOpen ? 'open' : 'closed'}
        transition={{ type: 'ease' }}
        style={{
          width: 56,
          height: 56,
          position: 'absolute',
          zIndex: 10,
        }}>
        <IconButton
          bg='white'
          color='black'
          boxShadow='xl'
          borderWidth='1px'
          borderColor='gray.300'
          onClick={onToggle}
          icon={isOpen ? <ChevronLeftIcon /> : <ChevronRightIcon />}
          aria-label='開啟關閉租車列表'
        />
      </motion.div>
    </>
  );
};

export default BuildingList;
