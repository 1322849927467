import { useCallback } from 'react';
import { Wrapper, Status } from '@googlemaps/react-wrapper';
import { Flex, Spinner, Box, Show } from '@chakra-ui/react';
import { useAtomValue, useAtom, useSetAtom } from 'jotai';
import { buildingsAtom, selectedBuildingIdAtom } from '@/atoms/buildings';
import {
  filterGeoLocationAtom,
  filterCacheGeoLocationAtom,
  filterMapBoundsAtom,
  filterMapZoomAtom,
  isFilterLocationListOpenAtom,
} from '@/atoms/filter';

import FilterLocationInput from '@/components/shared/Navbar/FilterLocationInput';
import MapInner from './MapInner';
import MapPointContainer from './MapPointContainer';
import MobileMapPointCardList from './MobileMapPointCardList';
import MobileMapPointCard from './MobileMapPointCard';

import mapStyles from './mapStyles.json';

const render = (status) => {
  if (status === Status.FAILURE) {
    console.log('Google Maps API failed to load.');
    return null;
  }
  return (
    <Flex w='100%' h='100%' justify='center' p={8}>
      <Spinner
        size='lg'
        thickness='4px'
        speed='0.65s'
        emptyColor='gray.200'
        color='black'
      />
    </Flex>
  );
};

// Map 元件階層
// Map
//  > MapInner (初始化 Google Map)
//    > MapPoint (MapOverlayView 將內容放在 Google Map 上)
const Map = () => {
  const center = useAtomValue(filterGeoLocationAtom);
  const setCachedCenter = useSetAtom(filterCacheGeoLocationAtom);
  const [zoom, setZoom] = useAtom(filterMapZoomAtom);
  const setBounds = useSetAtom(filterMapBoundsAtom);
  const buildings = useAtomValue(buildingsAtom);
  const setIsFilterLocationListOpen = useSetAtom(isFilterLocationListOpenAtom);

  // 開關卡片訂閱 atom
  const [selectBuildingId, setSelectBuildingId] = useAtom(
    selectedBuildingIdAtom,
  );
  const selectBuilding = buildings?.find(
    (building) => building.id === selectBuildingId,
  );

  // 處理座標重疊，手機版顯示問題
  const sameLatLonBuildings = buildings?.filter(
    (building) =>
      building.lat === selectBuilding?.lat &&
      building.lon === selectBuilding?.lon,
  );

  const handleMapClick = useCallback((e) => {
    setSelectBuildingId(null);
    setIsFilterLocationListOpen(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleMapDrag = useCallback(() => {
    // console.log('Google Map: onDrag');
  }, []);

  const handleMapBoundsChanged = useCallback(
    (map) => {
      /**
       * map.getBounds() 回傳的是 LatLngBounds 物件
       * Ia: Object { lo: 121.507224682312, hi: 121.56773531768798 }
       * Ua: Object { lo: 25.050294531599928, hi: 25.065844975085803 }
       * lo: 25.058069753342865,121.507224682312 (左下角座標)
       * hi: 25.065844975085803,121.56773531768798 (右上角座標)
       */

      const bounds = map.getBounds();
      const ne = bounds.getNorthEast();
      const sw = bounds.getSouthWest();
      setBounds({
        ne: { lat: ne.lat(), lng: ne.lng() },
        sw: { lat: sw.lat(), lng: sw.lng() },
      });

      // console.log('map:onBoundsChanged', map.getBounds());
    },
    [setBounds],
  );

  const handleMapCenterChanged = useCallback((map) => {
    const center = map.getCenter();
    setCachedCenter({ lat: center.lat(), lng: center.lng() });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleMapZoomChanged = useCallback((map) => {
    setZoom(map.getZoom());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!process.env.NEXT_PUBLIC_GOOGLE_MAP_API_KEY) {
    console.log('Miss Google API Key.');
    return null;
  }

  return (
    <Wrapper
      apiKey={process.env.NEXT_PUBLIC_GOOGLE_MAP_API_KEY}
      render={render}>
      <Show below='md'>
        <Box w='100%' px={4} pos='absolute' lef={0} top={4} zIndex={1009}>
          <FilterLocationInput />
        </Box>
      </Show>
      <Show below='md'>
        {sameLatLonBuildings?.length > 1 ? (
          <MobileMapPointCardList data={sameLatLonBuildings} />
        ) : (
          <MobileMapPointCard data={selectBuilding} />
        )}
      </Show>
      <input
        type='hidden'
        id='m'
        value={process.env.NEXT_PUBLIC_GOOGLE_MAP_API_KEY}
      />
      <MapInner
        center={center}
        zoom={zoom}
        onClick={handleMapClick}
        onDrag={handleMapDrag}
        onBoundsChanged={handleMapBoundsChanged}
        onCenterChanged={handleMapCenterChanged}
        onZoomChanged={handleMapZoomChanged}
        mapStyles={mapStyles}>
        {buildings?.map((point) => {
          return (
            <MapPointContainer
              key={`map-point-${point.id}`}
              data={point}
              isOpen={point.id === selectBuildingId}
            />
          );
        })}
      </MapInner>
    </Wrapper>
  );
};

export default Map;
