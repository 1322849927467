import styled from '@emotion/styled';

const NavButton = styled.button`
  width: 32px;
  height: 32px;
  background-color: white;
  border-radius: 50%;
  box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.2);
  color: black;
  position: absolute;
  top: 45%;
  z-index: 10;

  &.prev {
    left: 16px;
  }

  &.next {
    right: 16px;
  }

  &:hover {
    background-color: black;
    color: white;
  }
`;

export default NavButton;
