import {
  useEffect,
  useRef,
  useState,
  Children,
  isValidElement,
  cloneElement,
  memo,
} from 'react';
import { Box } from '@chakra-ui/react';
import { MarkerClusterer } from '@googlemaps/markerclusterer';
import { DEFAULT_MAP_CENTER, DEFAULT_MAP_ZOOM } from '@/constants';

const MapInner = ({
  center = DEFAULT_MAP_CENTER,
  zoom = DEFAULT_MAP_ZOOM,
  onClick,
  onDrag,
  onBoundsChanged,
  onCenterChanged,
  onZoomChanged,
  children,
  mapStyles,
}) => {
  const ref = useRef(null);
  const [map, setMap] = useState(null);
  const [cluster, setCluster] = useState(null);

  useEffect(() => {
    if (ref.current && !map) {
      const googleMap = new window.google.maps.Map(ref.current, {
        center,
        zoom,
        disableDefaultUI: true,
        clickableIcons: false,
        disableDoubleClickZoom: true,
        gestureHandling: 'greedy',
        styles: mapStyles,
      });
      googleMap.addListener('click', onClick);
      googleMap.addListener('drag', onDrag);
      googleMap.addListener('bounds_changed', () => {
        onBoundsChanged(googleMap);
      });
      googleMap.addListener('center_changed', () => {
        onCenterChanged(googleMap);
      });
      googleMap.addListener('zoom_changed', () => {
        onZoomChanged(googleMap);
      });

      var getGoogleClusterInlineSvg = function (color) {
        var encoded = window.btoa(
          '<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="-100 -100 200 200"><defs><g id="a" transform="rotate(45)"><path d="M0 47A47 47 0 0 0 47 0L62 0A62 62 0 0 1 0 62Z" fill-opacity="0.7"/><path d="M0 67A67 67 0 0 0 67 0L81 0A81 81 0 0 1 0 81Z" fill-opacity="0.5"/><path d="M0 86A86 86 0 0 0 86 0L100 0A100 100 0 0 1 0 100Z" fill-opacity="0.3"/></g></defs><g fill="' +
            color +
            '"><circle r="42"/><use xlink:href="#a"/><g transform="rotate(120)"><use xlink:href="#a"/></g><g transform="rotate(240)"><use xlink:href="#a"/></g></g></svg>',
        );

        return 'data:image/svg+xml;base64,' + encoded;
      };

      const clusterStyles = [
        {
          width: 40,
          height: 40,
          url: getGoogleClusterInlineSvg('blue'),
          textColor: 'white',
          textSize: 12,
        },
        {
          width: 50,
          height: 50,
          url: getGoogleClusterInlineSvg('violet'),
          textColor: 'white',
          textSize: 14,
        },
        {
          width: 60,
          height: 60,
          url: getGoogleClusterInlineSvg('yellow'),
          textColor: 'white',
          textSize: 16,
        },
      ];

      const googleCluster = new MarkerClusterer({
        map: googleMap,
        styles: clusterStyles,
      });

      setMap(googleMap);
      setCluster(googleCluster);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref.current]);

  useEffect(() => {
    if (map) {
      map.setCenter(center);
    }
  }, [map, center]);

  useEffect(() => {
    if (map) {
      map.setZoom(zoom);
    }
  }, [map, zoom]);

  useEffect(() => {
    let listener;
    if (map) {
      listener = map.addListener('click', onClick);
    }
    return () => {
      listener?.remove();
    };
  }, [map, onClick]);

  useEffect(() => {
    let listener;
    if (map) {
      listener = map.addListener('drag', onDrag);
    }
    return () => {
      listener?.remove();
    };
  }, [map, onDrag]);

  useEffect(() => {
    let listener;
    if (map) {
      listener = map.addListener('bounds_changed', () => {
        onBoundsChanged(map);
      });
    }
    return () => {
      listener?.remove();
    };
  }, [map, onBoundsChanged]);

  useEffect(() => {
    let listener;
    if (map) {
      listener = map.addListener('center_changed', () => {
        onCenterChanged(map);
      });
    }
    return () => {
      listener?.remove();
    };
  }, [map, onCenterChanged]);

  return (
    <>
      <Box ref={ref} id='map' w='100%' h='100%'></Box>
      {Children.map(children, (child) => {
        if (isValidElement(child)) {
          return cloneElement(child, {
            key: child.props.data.id,
            map,
            cluster,
          });
        }
      })}
    </>
  );
};

export default memo(MapInner);
