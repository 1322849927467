import { Box } from '@chakra-ui/react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from 'swiper';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

import MobileMapPointCardListInner from './MobileMapPointCardListInner';

const MobileMapPointCardList = ({ data }) => {
  return (
    <Box pos='fixed' lef='10%' bottom={10} zIndex={1009} w='100%' h='140px'>
      <Box pos='relative' w='100%' h='100%'>
        <Swiper
          slidesPerView={1.3}
          spaceBetween={30}
          pagination={{
            clickable: true,
          }}
          modules={[Pagination]}>
          {data.map((item, index) => (
            <SwiperSlide key={`mobile-map-point-card-list-${item.id}-${index}`}>
              <MobileMapPointCardListInner data={item} />
            </SwiperSlide>
          ))}
        </Swiper>
      </Box>
    </Box>
  );
};

export default MobileMapPointCardList;
