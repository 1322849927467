import React from 'react';
import { Flex, useBreakpointValue } from '@chakra-ui/react';

import Image from 'next/image';

import mapMarkerImage from '@/assets/images/icon-map-marker-blue.png';
import mapBlackWhiteMarkerImage from '@/assets/images/icon-bw-map-marker.png';

import MapOverlayView from './MapOverlayView';
// import MapPointButton from './MapPointButton';
import MapPointCard from './MapPointCard';

const MapPointPresentation = ({ map, cluster, data, isOpen, onClick }) => {
  const isDesktop = useBreakpointValue({ base: false, md: true });
  if (typeof data.lat !== 'number' || typeof data.lon !== 'number') {
    return;
  }

  return (
    <>
      {map && (
        <MapOverlayView
          position={{ lat: data.lat, lng: data.lon }}
          map={map}
          cluster={cluster}
          active={isOpen}>
          <Flex
            direction='column'
            justifyContent='center'
            alignItems='center'
            position='relative'
            onTouchStart={(e) => {
              // Avoid click event on Google Map.
              if (e.cancelable) {
                e.stopPropagation();
              }
            }}
            onClick={(e) => {
              // Avoid click event on Google Map.
              if (e.cancelable) {
                e.stopPropagation();
              }
            }}>
            {isOpen && isDesktop && <MapPointCard data={data} />}
            <button onClick={onClick}>
              <Image
                src={
                  data?.subscription_solution?.fully_booked
                    ? mapBlackWhiteMarkerImage
                    : mapMarkerImage
                }
                alt='租'
                width='30px'
                height='45px'
                priority
              />
            </button>
            {/* {data?.min_price > 0 ? (
              <MapPointButton
                text={`$${data.min_price}起`}
                onClick={onClick}
                isOpen={data.isOpen}
              />
            ) : (
              <button onClick={onClick}>
                <Image src={mapMarkerImage} alt='租' width={40} height={40} />
              </button>
            )} */}
          </Flex>
        </MapOverlayView>
      )}
    </>
  );
};

export default MapPointPresentation;
