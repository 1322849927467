import { useToast } from '@chakra-ui/react';
import { useAtom, useSetAtom } from 'jotai';
import { flashMessageAtom, clearFlashMessageAtom } from '@/atoms/flashMessage';

export const useFlashMessage = () => {
  const toast = useToast();
  const [flashMessage, setFlashMessage] = useAtom(flashMessageAtom);
  const dismiss = useSetAtom(clearFlashMessageAtom);

  const set = ({ message, type = 'success' }) => {
    setFlashMessage({
      message,
      type,
      isOpen: true,
    });
  };

  const consume = () => {
    if (flashMessage.isOpen) {
      toast({
        title: flashMessage.message,
        status: flashMessage.type,
        duration: 5000,
        position: 'top-right',
        isClosable: true,
        isClosable: true,
      });
      dismiss();
    }
  };

  return {
    dismiss,
    set,
    consume,
  };
};
