import { atom } from 'jotai';

export const liveChatSessionVariablesAtom = atom({
  name: '訪客',
  email: '',
  phone: '',
});
export const liveChatSessionVariablesMessageAtom = atom('');

// minified, maximized, hidden
export const liveChatVisibilityAtom = atom('minimized');

export const openLiveChatAtom = atom(null, (get, set) => {
  set(liveChatVisibilityAtom, 'maximized');
});

export const closeLiveChatAtom = atom(null, (get, set) => {
  set(liveChatVisibilityAtom, 'minimized');
});
