import { Box, Show } from '@chakra-ui/react';

import BuildingList from './BuildingList';
import Map from './Map';

const Main = () => {
  return (
    <>
      <Show above='md'>
        <BuildingList />
      </Show>

      <Box w='100%' h='calc(100vh - 60px)'>
        <Map />
      </Box>
    </>
  );
};

export default Main;
