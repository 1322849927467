import { useRef, useCallback, Fragment } from 'react';
import { Flex, Box, Text, Link as ChakraLink, Badge } from '@chakra-ui/react';
import { useSetAtom } from 'jotai';
import { filterGeoLocationAtom } from '@/atoms/filter';
import NextLink from 'next/link';
import { useSearchParams } from 'next/navigation';
import Image from 'next/image';
import { Swiper, SwiperSlide } from 'swiper/react';
import { ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons';
import { Navigation, Pagination } from 'swiper';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

import NavButton from '@/components/shared/SwiperNavButton';

const MapPointCard = ({ data }) => {
  const setFilterGeoLocation = useSetAtom(filterGeoLocationAtom);
  const searchParams = useSearchParams();
  const queryString = searchParams.toString();

  const prevRef = useRef(null);
  const nextRef = useRef(null);
  const {
    id: building_id,
    spaces,
    name,
    min_price,
    max_price,
    tags,
    subscription_solution,
    is_subscription_productify,
  } = data;

  const shortBuildingId =
    building_id.length === 36 ? building_id.substring(0, 8) : building_id;

  const renderBullet = useCallback((_, className) => {
    return `<span class="${className}">${' '}</span>`;
  }, []);

  const handleClickItem = () => {
    setFilterGeoLocation({
      lat: data.lat,
      lng: data.lon,
    });
  };

  return (
    <Flex
      w={320}
      h={340}
      bg='white'
      position='absolute'
      bottom='48px'
      direction='column'
      boxShadow='md'
      borderRadius='lg'
      borderWidth='1px'
      borderColor='gray.200'>
      <Box overflow='hidden' w='100%' h={240} pos='relative' bg='gray.100'>
        <Swiper
          slidesPerView={1}
          autoHeight={true}
          modules={[Navigation, Pagination]}
          pagination={{
            clickable: true,
            renderBullet,
          }}
          navigation={{
            prevEl: prevRef.current,
            nextEl: nextRef.current,
          }}
          onInit={(swiper) => {
            swiper.params.navigation.prevEl = prevRef.current;
            swiper.params.navigation.nextEl = nextRef.current;
            swiper.navigation.init();
            swiper.navigation.update();
          }}>
          {/* {spaces.length === 0 && (
            <Box
              w='100%'
              minH={180}
              bg='gray.100'
              borderRadius={8}
              overflow='hidden'
              cursor='grab'></Box>
          )} */}
          {spaces
            .slice(Math.max(data?.spaces.length - 1, 0))
            .map(({ id, url, name, entry_url }) => (
              <Fragment key={`map-point-card-${id}`}>
                <SwiperSlide>
                  <Box borderTopRadius='lg' overflow='hidden' minH={240}>
                    {entry_url && (
                      <ChakraLink
                        href={`/b/${shortBuildingId}${queryString ? `?${queryString}` : ''}`}
                        target='_blank'
                        pos='relative'
                        display='block'
                        w='100%'
                        h={240}
                        bg='black'
                        onClick={handleClickItem}>
                        <Image
                          src={entry_url}
                          fill
                          sizes='100% 100%'
                          alt={name}
                          style={{
                            borderTopRightRadius: 8,
                            borderTopLeftRadius: 8,
                            objectFit: 'cover',
                          }}
                        />
                      </ChakraLink>
                    )}
                  </Box>
                </SwiperSlide>
                <SwiperSlide>
                  <Box borderTopRadius='lg' overflow='hidden' minH={240}>
                    {url && (
                      <ChakraLink
                        href={`/b/${shortBuildingId}${queryString ? `?${queryString}` : ''}`}
                        target='_blank'
                        pos='relative'
                        display='block'
                        w='100%'
                        h={240}
                        bg='black'
                        onClick={handleClickItem}>
                        <Image
                          src={url}
                          fill
                          sizes='100% 100%'
                          alt={name}
                          style={{
                            borderTopRightRadius: 8,
                            borderTopLeftRadius: 8,
                            objectFit: 'cover',
                          }}
                        />
                      </ChakraLink>
                    )}
                  </Box>
                </SwiperSlide>
              </Fragment>
            ))}
          <NavButton className='prev' aria-label='上一張圖片' ref={prevRef}>
            <ChevronLeftIcon w={6} h={6} />
          </NavButton>
          <NavButton className='next' aria-label='下一張圖片' ref={nextRef}>
            <ChevronRightIcon w={6} h={6} />
          </NavButton>
        </Swiper>
      </Box>
      <Flex direction='column' p={4} cursor='pointer'>
        <a
          href={`/b/${shortBuildingId}${queryString ? `?${queryString}` : ''}`}
          onClick={handleClickItem}
          target='_blank'>
          <Flex alignItems='center'>
            {subscription_solution?.fully_booked ? (
              <Badge colorScheme='red' mr={2} mt={2}>
                預約候補
              </Badge>
            ) : null}
            <Text fontWeight={500} fontSize={16}>
              {name}
            </Text>
          </Flex>

          <Text fontSize={14} color='primary.gray' noOfLines={2}>
            {tags.map((tag) => tag.name).join(' ')}
          </Text>

          {!is_subscription_productify && subscription_solution && (
            <Text fontSize={14} color='primary.gray' noOfLines={2}>
              {subscription_solution.description}
            </Text>
          )}

          <Flex align='center' fontSize={16}>
            {data?.min_price && (
              <>
                <Text>${data.min_price}</Text>
                <Text color='primary.gray' mx='2'>
                  TWD/月起
                </Text>
              </>
            )}
          </Flex>
        </a>
      </Flex>
    </Flex>
  );
};

export default MapPointCard;
